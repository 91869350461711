import React, {useRef, useState, useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { errorMessage } from '../errorHandler';
import resourcesServices from '../services/resourcesServices';
import imageCompression from 'browser-image-compression';

const CompartirFoto = () => {
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [imageSrc, setImageSrc] = useState(null);
  const [imageGalery, setImageGalery] = useState(null);
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {

    // localStorage.removeItem('idBailongo');
    const token = localStorage.getItem('idBailongo');
    if(!token){
      localStorage.setItem('idBailongo', generateRandomAlphanumeric(20));
    }

  }, []);

  const generateRandomAlphanumeric= (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const videoConstraints = {
    facingMode: isFrontCamera ? "user" : "environment"
  };

  const compressImage = async (imgFile) => {
    setSaving(true)
    // Configuración de opciones de compresión
    const options = {
      maxSizeMB: 1, // Tamaño máximo en MB
      maxWidthOrHeight: 1024, // Dimensión máxima
      useWebWorker: true, // Habilita el uso de web workers
    };

    try {
      // Comprimir la imagen
      const compressedFile = await imageCompression(imgFile, options);
      console.log(compressedFile)
      setImageGalery(compressedFile);
      // setCompressedImage(URL.createObjectURL(compressedFile));

    } catch (error) {
      alert('Error al comprimir la imagen:', error);
    }
    setSaving(false)
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    // setImageSrc(URL.createObjectURL(imageSrc));
    const imgFile = dataURLtoBlob(imageSrc)

    compressImage(imgFile)

    setImageSrc(URL.createObjectURL(imgFile));
  }, [webcamRef]);

  const buscarEnGaleria = () => {
    fileInputRef.current.click()
  };

  const toggleCamera = () => {
    setIsFrontCamera(prevState => !prevState);
  };

  const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}

  const salir = () => {
    setImageSrc(null)
    navigate(`/acceso`);
  };

  const enviarFoto = async () => {
    setSaving(true)
    try {
      if(imageSrc || imageGalery){
        const nombreBailongo = localStorage.getItem('nombreBailongo');
        const idBailongo = localStorage.getItem('idBailongo');

        const userName = window.prompt("Por favor, ingresa tu nombre:", nombreBailongo || '');

        if(userName){
          localStorage.setItem('nombreBailongo', userName);
        } else {
          alert("Si no ingresa su nombre no va a poder participar de posibles sorteos.")
          return false;
        }

        const formData = new FormData();
        formData.append('multimedia_usuario[imagen]', imageGalery, imageGalery.name)
        formData.append('multimedia_usuario[id_web]', idBailongo)
        formData.append('multimedia_usuario[nombre_usuario]', userName)

        await resourcesServices.crearResource('multimedia_usuarios', formData, true);
        alert("Imagen enviada")

        setImageSrc(null)
      }

    } catch (error) {
      errorMessage(error)
    }
    setSaving(false)
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setImageSrc(URL.createObjectURL(file));

    compressImage(file)
  };

  const nuevaFoto = () => {
    setImageSrc(null)
  };

  return (
    <div className="container p-0 bg-main position-relative vh-100">
      {saving ? (
        <>
        <div className="position-absolute top-0 start-0 w-100 h-100">
          <h3 className='mt-3 text-center'>Aguarde</h3>
        </div>

        </>
      ) : (
        <>
          {imageSrc ? (
            <>
              <div className="position-absolute top-0 start-0 w-100 h-100">
                <img src={imageSrc} alt="Foto capturada" className="img-fluid w-100 h-100" style={{ objectFit: 'cover' }} />
              </div>
              <div className="position-absolute top-0 start-50 translate-middle-x mt-3">
                <div className="btn-group" role="group">
                  <button onClick={() => enviarFoto()} className="btn btn-success">Enviar</button>
                  <button onClick={() => nuevaFoto()} className="btn btn-danger">Nueva Foto</button>
                </div>

              </div>
              <div className="position-absolute bottom-0 start-50 translate-middle-x mb-5">
                <div>{localStorage.getItem('idBailongo')}</div>
              </div>
              </>
          ) : (
            <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            className="w-100 h-100"
            style={{ objectFit: 'cover' }}
          />
          <div className="position-absolute top-0 start-50 translate-middle-x mt-3">
            <div className="btn-group" role="group">
              <button onClick={() => salir()} className="btn btn-danger">Salir</button>
            </div>
          </div>

          <div className="position-absolute bottom-0 start-50 translate-middle-x mb-5">
            <div className="btn-group" role="group">
              <button onClick={capture} className="btn btn-primary">Tomar Foto</button>
              <button onClick={toggleCamera} className="btn btn-secondary">Rotar Cámara</button>

              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                ref={fileInputRef}
                className="d-none"
              />
              <button onClick={() => buscarEnGaleria()} className="btn btn-success">Galería</button>

            </div>
          </div>
            </>
          )}
        </>
      )}


    </div>
  );
};

export default CompartirFoto;