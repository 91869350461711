import React, { useEffect, useState, useContext } from 'react';
import resourcesServices from '../services/resourcesServices';
import { InputContext } from '../contexts/InputContext';
import { errorMessage } from '../errorHandler';

const MiMultimedia = () => {
  const [authInformation, setAuthInformation] = useContext(InputContext)
  const [mensajes, setMensajes] = useState([]);
  const [header, setHeader] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    obtenerMensajes('', '', 1)
  }, []);

  useEffect(() => {
    if(selectedFilter!==null){
      obtenerPagina()
    }
  }, [selectedFilter]);

  const obtenerPagina = async (page=1) => {
    if(selectedFilter!==null){
      obtenerMensajes('', '', page, 'estado=' + selectedFilter)
    } else {
      obtenerMensajes('', '', page, '')
    }
  };

  const obtenerMensajes = async (sortBy='', q='', page=1, otro='') => {
    try {
      const data = await resourcesServices.obtenerResources('multimedia_usuarios', sortBy, q, page, otro);
      if (page === 1) {
        setMensajes(data.data);
      } else {
        setMensajes([...mensajes, ...data.data]);
      }

      setHeader(data.headers);

    } catch (error) {
      errorMessage(error)
    }
  };

  const colorEstadoMensaje = (estado) => {
    if(estado === "pendiente"){
      return "text-bg-secondary"
    } else if(estado === "aprobado"){
      return "text-bg-success"
    } else if(estado === "rechazado"){
      return "text-bg-danger"
    }

  }

  const estadoMensaje = async (id, estado) => {
    try {
      const data = await resourcesServices.actualizarResource('multimedia_usuarios', id, {multimedia_usuario: {estado: estado}});

      setMensajes(prevDatos => {
        return prevDatos.map(objeto => {
          if (objeto.id === data.id) {
            // Si encontramos el objeto con el ID, actualizamos el valor
            return { ...objeto, estado: data.estado };
          }
          // Si no es el objeto que estamos buscando, lo dejamos sin cambios
          return objeto;
        });
      });

      // obtenerMensajes()
    } catch (error) {
      errorMessage(error)
    }
  };

  const eliminarMensaje = async (id) => {
    try {
      if(!window.confirm('Está seguro que quiere eliminar el mensaje ?')){
        return;
      }

      await resourcesServices.eliminarResource('multimedia_usuarios', id);
      const copiaMensajes = [...mensajes];
      setMensajes(copiaMensajes.filter((mensajes) => mensajes.id !== id))


      // obtenerMensajes()
    } catch (error) {
      errorMessage(error)
    }
  };

  return (
    <div>
      <h3>Multimedia</h3>
      <div className="btn-group ms-3" role="group" aria-label="Basic example">
        <button onClick={()=>setSelectedFilter('')} className='btn btn-outline-secondary'>Todos</button>
        <button onClick={()=>setSelectedFilter('pendiente')} className='btn btn-outline-secondary'>Pendientes</button>
        <button onClick={()=>setSelectedFilter('aprobado')} className='btn btn-outline-secondary'>Aprobados</button>
        <button onClick={()=>setSelectedFilter('rechazado')} className='btn btn-outline-secondary'>Rechazados</button>
      </div>
      {
        (authInformation.rol === "Organizador" || authInformation.rol === "Admin") ? (
          <>
            <h5 className='mt-3'>Total: {header && header["total-count"]}</h5>
            <ul className="list-group list-group-flush mt-3">
              {
                mensajes.map((mensaje, index) => (
                  <li key={index} className="list-group-item text-start">
                    <span className={`badge ${colorEstadoMensaje(mensaje.estado)}`}>{mensaje.estado}</span>
                    { mensaje.originalUrl && (
                      <div className='image-container'>
                        <img src={mensaje.originalUrl} className='img-fluid' alt="image" />
                      </div>
                    )}

                    <div className='ms-3'>
                      <b>IP:</b> {mensaje.ip}<br />
                      <b>Id Web:</b> {mensaje.id_web}<br />
                      <b>Nombre:</b> {mensaje.nombre_usuario}<br />
                    </div>
                    <div className="btn-group ms-3" role="group" aria-label="Basic example">
                      {
                        mensaje.estado !== "aprobado" && (
                          <button onClick={()=>estadoMensaje(mensaje.id, "aprobado")} className='btn btn-success'>Aprobar mensaje</button>
                        )
                      }
                      {
                        mensaje.estado !== "rechazado" && (
                          <button onClick={()=>estadoMensaje(mensaje.id, "rechazado")} className='btn btn-warning'>Rechazar mensaje</button>
                        )
                      }
                      <button onClick={()=>eliminarMensaje(mensaje.id)} className='btn btn-danger'>Eliminar</button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </>
        ) : (
          <>
            <ul className="list-group list-group-flush mt-3">
              {
                mensajes.map((mensaje, index) => (
                  <li key={index} className="list-group-item text-start">
                    <span className={`badge ${colorEstadoMensaje(mensaje.estado)}`}>{mensaje.estado}</span>
                    { mensaje.originalUrl && (
                      <div className='image-container'>
                        <img src={mensaje.originalUrl} className='img-fluid' alt="image" />
                      </div>
                    )}
                    <div className='ms-3'>{mensaje.received_message}</div>
                    <div className="btn-group ms-3" role="group" aria-label="Basic example">
                      <button onClick={()=>eliminarMensaje(mensaje.id)} className='btn btn-danger'>Eliminar</button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </>
        )
      }

      {
        header["total-pages"] !== header["current-page"] && (
          <div className='p-3'>
            <button onClick={()=>obtenerPagina(header["current-page"] + 1)} className='btn btn-success mt-3'>Ver más | {header["current-page"]} de {header["total-pages"]}</button>
          </div>
        )
      }
    </div>
  );
};

export default MiMultimedia;