import { useEffect, useState} from 'react'
import resourcesServices from '../../services/resourcesServices'
import { errorMessage } from '../../errorHandler';

const Imagenes = ({isTest}) => {
  const [images, setImages] = useState([]);
  const [indiceImagen, setIndiceImagen] = useState(0);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    console.log("Obtengo imagenes")
    obtenerMensajes()

  }, []);

  // Animacion de imagenes
  useEffect(() => {
    const cambiarImagen = () => {
      setIndiceImagen((prev) => (prev + 1) % images.length);
    };

    if(running){
      const intervalo = setInterval(cambiarImagen, 5*1000);
      return () => clearInterval(intervalo);
    }
  }, [images]);

  // Carga de nuevas imagenes cada 1 minuto
  useEffect(() => {
    const cargaImagenes = () => {
      const idsVar = images.map(v => v["id"]).join(",")

      obtenerMensajes(`ids=${idsVar}`)
    };

    if(running){
      const intervalo = setInterval(cargaImagenes, 60*1000);
      return () => clearInterval(intervalo);
    }

  }, [images]);

  const obtenerMensajes = async (otro = 'ids=') => {
    try {
      const data = await resourcesServices.obtenerResources(`multimedia_usuarios/multimediaPublico`, '', '', 1, otro + `${isTest ? '&test=true' : ''}`);

      setRunning(true);

      if(data.data.length > 0){
        setImages(images.concat(data.data));
      }
    } catch (error) {
      errorMessage(error)
    }
  };

  return (
    <div className='position-relative z-3'>
      <div className="position-absolute top-0 end-0 w-50 h-25 d-inline-block environment-3d">
        {
          running && images[indiceImagen] && (
            <>
              {
                images[indiceImagen]['originalUrl'] && (
                  <div className='only-image'>
                    <span className='image' style={{transition: 'background 2s ease-in-out',
                  background: `url(${images[indiceImagen]['originalUrl']}) center/contain no-repeat`,}}></span>
                  </div>
                )
              }
              {/* {
                images[indiceImagen]['content_type'] === 'image' ? (
                  <div className='only-image'>
                    {
                    images[indiceImagen]['received_message'].length>0 && (
                      <span className='text'>{images[indiceImagen]['received_message']}</span>
                    )
                    }
                    <span className='image' style={{transition: 'background 2s ease-in-out',
                  background: `url(${images[indiceImagen]['originalUrl']}) center/contain no-repeat`,}}></span>
                  </div>
                ) : (
                    images[indiceImagen]['received_message'].length>0 && (
                      <div className='only-text'>
                        <span>{images[indiceImagen]['received_message']}</span>
                      </div>
                    )
                )
              } */}
            </>
          )
        }
      </div>
    </div>
  );
}

export default Imagenes;